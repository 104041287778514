.splash-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-bar {
  width: 0%;
  height: 5px;
  background-color: #007bff; /* Adjust color as needed */
}

.coming-soon {
  margin-top: 20px;
  font-size: 24px;
  color: #333; /* Adjust color as needed */
}
